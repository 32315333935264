import React, { useState, useEffect } from "react";
import Section from "../../Common/Section";
import { IoCopyOutline } from "react-icons/io5";
import Button from "../../Common/Button";
import { PrismicRichText } from "@prismicio/react";
import axios from "axios";
import "react-dropdown/style.css";
import "./index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "./components/Input/index";
import { IoCloseOutline } from "react-icons/io5";
import useCommonStore from "../../../stores/use-common-store";

const isDev = process.env.NODE_ENV === "development";
export const devValue = (value) => (isDev ? value : "");

const ModelForm = () => {
  const isContactModalOpen = useCommonStore(
    (state) => state.isContactModalOpen,
  );

  const setIsContactModalOpen = useCommonStore(
    (state) => state.setIsContactModalOpen,
  );

  const [name, setName] = useState(devValue("Mahesh"));
  const [email, setEmail] = useState(devValue("mahesh@atdigital.io"));
  const [services, setServices] = useState(devValue("Shopify"));
  const [subject, setSubject] = useState(devValue("Subject"));
  const [msg, setMsg] = useState(devValue("Message"));
  const [success, setSuccess] = useState(false);
  const [runDataLayer, setRunDataLayer] = useState(false);
  const [fail, setFail] = useState(false);

  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (window === undefined) return;
    if (!runDataLayer) return;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "ContactUsForm",
    });
    setRunDataLayer(false);
  }, [runDataLayer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setFail(false);
    // setSuccess(false);

    setProgress(true);

    // =============== HUBSPOT ===============
    await axios
      .post("/api/contact-us-hubspot", {
        formData: {
          firstName: name,
          email: email,
          services: services,
          subject: subject,
          message: msg,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(
            "Thank you for your message. We will be in touch with you soon.",
            {
              position: toast.POSITION.TOP_CENTER,
              closeOnClick: false,
            },
          );
          setSuccess(true);
          setRunDataLayer(true);
          if (!isDev) {
            setName("");
            setEmail("");
            setServices("");
            setSubject("");
            setMsg("");
          }
        } else {
          setFail(true);
          toast.error(
            <>
              Something went wrong! Try again, or please write to us at{" "}
              <a href="mailto:hello@atdigital.io">hello@atdigital.io</a>
            </>,
            {
              position: toast.POSITION.TOP_CENTER,
              closeOnClick: false,
            },
          );
        }
      })
      .catch((e) => {
        setFail(true);
      })
      .finally(() => {
        setProgress(false);
        setIsContactModalOpen(false);
      });
  };

  if (!isContactModalOpen) return null;

  return (
    <div
      onClick={() => setIsContactModalOpen(false)}
      role={"presentation"}
      className="fixed h-screen w-screen right-0 z-[99999] bg-black bg-opacity-60 top-0 left-0 justify-center"
    >
      <div className="max-w-[600px] mt-[100px] h-auto w-full mx-auto rounded-[16px] overflow-hidden bg-white">
        <div
          className="relative z-[999999] pb-[80px] md:pb-0 max-h-[calc(100vh-120px)] overflow-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-[#ffffff] min-w-[300px] p-[20px] md:p-[24px] lg:py-[40px] lg:px-[40px]">
            <div
              className="absolute right-[26px] top-[20px] cursor-pointer mb-[80px]"
              onClick={() => {
                setIsContactModalOpen(false);
              }}
            >
              <IoCloseOutline
                size="24px"
                color="#717680"
                className="text-[30px] md:text-[48px]"
              />
            </div>

            <h4 className="text-left head semi-bold mb-[16px]">
              Get in touch with us
            </h4>
            {/* form area */}
            <form onSubmit={handleSubmit} className="grid gap-y-[16px]">
              <Input type="text" label="Name" value={name} setValue={setName} />

              <Input
                type="text"
                label="Email"
                value={email}
                setValue={setEmail}
              />

              <div class="relative">
                <select
                  name="Services"
                  id="Services"
                  className="w-full px-[18px] pt-[11px] pb-[0px] h-[53px] rounded-[6px] bg-white text-[#211F24] text-[14px] placeholder-transparent border-[1px] border-[#B0B0B0] peer focus:outline-none focus:border-[#1570EF]"
                  value={services}
                  required
                  onChange={(e) => {
                    setServices(e.target.value);
                  }}
                >
                  <option disabled hidden></option>
                  <option value="PPC">PPC</option>
                  <option value="SEO">SEO</option>
                  <option value="Mobile App">Mobile App</option>
                  <option value="Web App">Web App</option>
                  <option value="Website">Website</option>
                  <option value="E-commerce Store">E-commerce Store</option>
                  <option value="Shopify">Shopify</option>
                  <option value="Team Augmentation">Team Augmentation</option>
                  <option value="Other">Other</option>
                </select>
                <label
                  htmlFor="Services"
                  className={`absolute left-[18px] ${
                    services
                      ? "top-[8px] text-[10px] translate-y-0"
                      : "top-[50%] translate-y-[-50%] text-[14px]"
                  } text-[#727A8B] transition-all peer-focus:top-[8px] peer-focus:text-[10px] peer-focus:translate-y-0`}
                >
                  Services
                </label>
              </div>

              <Input
                type="text"
                label="Subject"
                value={subject}
                setValue={setSubject}
              />

              <Input
                type="textarea"
                label="Your Message"
                value={msg}
                setValue={setMsg}
              />

              <button
                className="bg-[#4F46E5] rounded-[6px] px-[20px] py-[15px] text-[#FFFFFF] font-semibold leading-[20px] disabled:opacity-50"
                disabled={progress}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelForm;
