import React from "react";

const Section = ({ children, className }) => {
  return (
    <div
      className={`w-full py-[40px] px-[20px] md:py-[60px] md:px-[40px] xl:p-[80px] xxl:px-0 xxl:max-w-[1280px] mx-auto ${className}`}
    >
      {children}
    </div>
  );
};

export default Section;
