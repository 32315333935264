import React, { useRef } from "react";

const Input = ({ type = "text", label, value, setValue, rows = "5" }) => {
  const inputRef = useRef();

  const areaRef = useRef();

  const handleLabelClick = () => {
    inputRef.current.focus();
  };

  const handleareaLabelClick = () => {
    areaRef.current.focus();
  };
  return type === "textarea" ? (
    <div class="relative">
      <textarea
        name={label}
        id={label}
        rows={rows}
        value={value}
        ref={areaRef}
        onChange={(e) => setValue(e.target.value)}
        className="w-full px-[18px] pt-[20px] pb-[9px] rounded-[6px] bg-white text-[#211F24] text-[14px] placeholder-transparent border-[1px] border-[#B0B0B0] peer focus:outline-none focus:border-[#1570EF]"
        placeholder="Your Message"
        required
      ></textarea>
      <label
        htmlFor={label}
        onClick={handleareaLabelClick}
        className={`absolute left-[18px] ${
          value
            ? "top-[8px] text-[10px] translate-y-0"
            : "top-[20%] translate-y-[-50%] text-[14px]"
        } text-[#727A8B] transition-all peer-focus:top-[8px] peer-focus:text-[10px] peer-focus:translate-y-0`}
      >
        {label}
      </label>
    </div>
  ) : (
    <div class="relative">
      <input
        type={type}
        name={label}
        id={label}
        value={value}
        ref={inputRef}
        onChange={(e) => setValue(e.target.value)}
        className="w-full px-[18px] pt-[11px] pb-[0px] rounded-[6px] h-[53px] bg-white text-[#211F24] text-[14px] placeholder-transparent border-[1px] border-[#B0B0B0] peer focus:outline-none focus:border-[#1570EF]"
        placeholder="placeholder"
        required
      />
      {/* <label
                htmlFor={label}
                className={`absolute left-[18px] ${value ? "top-[7px] text-[10px] translate-y-0" : "top-[50%] translate-y-[-50%] text-[14px]"} text-[#727A8B] transition-all peer-focus:top-[7px] peer-focus:text-[10px] peer-focus:translate-y-0`}
            > */}
      <label
        onClick={handleLabelClick}
        htmlFor={label}
        className={`absolute left-[18px] ${
          value
            ? "top-[8px] text-[10px] translate-y-0"
            : "top-[50%] translate-y-[-50%] text-[14px]"
        } text-[#727A8B] transition-all peer-focus:top-[8px] peer-focus:text-[10px] peer-focus:translate-y-0`}
      >
        {label}
      </label>
    </div>
  );
};

export default Input;
