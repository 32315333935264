import React from "react";
import { IconContext } from "react-icons";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./src/styles/global.css";
import "@fontsource-variable/urbanist";
import "@fontsource-variable/kumbh-sans";
import { ToastContainer } from "react-toastify";
import ModalForm from "./src/components/Common/ModalForm";

const WrapWithProviders = ({ children }) => (
  <IconContext.Provider
    value={{
      style: { verticalAlign: "middle" },
      className: "global-class-name",
    }}
  >
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    {children}
    <ModalForm />
  </IconContext.Provider>
);

export default WrapWithProviders;
