import React from "react";
import { IoArrowForward, IoHeadsetOutline } from "react-icons/io5";
import { HiOutlinePhone } from "react-icons/hi2";
import { Link } from "gatsby";

import "./index.css";

const Button = ({
  name = "Start your project",
  className,
  noIcon = false,
  primaryIcon = true,
  secondary = false,
  smallbtn = false,
  to = "",
  type = "button",
  cta = false,
  newtab = false,
  mediumbtn = false,
}) => {
  const padding = smallbtn
    ? "px-[12px] py-[10px] md:px-[18px] md:py-[12px]"
    : mediumbtn
      ? "px-[16px] py-[12px] md:px-[20px] md:py-[15px]"
      : "px-5 py-[10px] md:px-[20px] md:py-[14px]";
  if (cta) {
    return (
      // <a href={to} target="_blank">
      <ButtonComponent
        type={type}
        name={name}
        noIcon={noIcon}
        primaryIcon={primaryIcon}
        secondary={secondary}
        padding={padding}
        className={className}
        to={to}
        newtab={true}
        cta={cta}
      />
      // </a>
    );
  }

  if (type === "submit") {
    return (
      <ButtonComponent
        type={type}
        name={name}
        noIcon={noIcon}
        primaryIcon={primaryIcon}
        secondary={secondary}
        padding={padding}
        className={className}
      />
    );
  } else {
    return (
      // <Link to={to} target={newtab ? "_blank" : undefined}>
      <ButtonComponent
        type={type}
        name={name}
        noIcon={noIcon}
        primaryIcon={primaryIcon}
        secondary={secondary}
        padding={padding}
        className={className}
        to={to}
        newtab={newtab}
      />
      // </Link>
    );
  }
};

export default Button;

const ButtonComponent = ({
  type,
  name,
  noIcon,
  primaryIcon,
  secondary,
  padding,
  className,
  to,
  newtab,
  cta,
}) => {
  return (
    <Link
      to={to}
      target={`${newtab ? "_blank" : ""} ${cta ? "_blank" : ""}`}
      className={`inline-flex group capitalize justify-betwee text-center items-center text-[15px] md:text-[16px] border rounded-[6px] transition-all linear duration-300 btn custom-btn mb-0 font-medium leading-[20px] ${padding} ${
        secondary
          ? "border-[#4F46E5] hover:border-[#4F46E5] bg-[#fff] hover:bg-[#4F46E5] text-[#4F46E5] hover:text-[#fff]"
          : "border-[#4F46E5] hover:border-[#fff] bg-[#4F46E5] hover:bg-[#090097] text-[#fff]"
      } ${className}`}
    >
      {name}
      {!noIcon && (
        <span
          className={`ml-[8px] text-[24px] ${
            secondary ? "secondary-button" : ""
          }`}
        >
          {primaryIcon ? (
            <IoArrowForward
              color={"#fff"}
              // style={{ width: "20px", height: "20px" }}
              className="w-[16px] h-[16px] md:w-[20px] md:h-[20px]"
            />
          ) : (
            <IoHeadsetOutline
              color={!secondary ? "#fff" : ""}
              className="text-[20px]"
            />
          )}
        </span>
      )}
    </Link>
  );
};

export const StyledButton = ({
  onClick,
  primaryIcon,
  noIcon = false,
  smallbtn,
  mediumbtn,
  className = "",
  name,
}) => {
  const padding = smallbtn
    ? "px-[12px] py-[10px] md:px-[18px] md:py-[12px]"
    : mediumbtn
      ? "px-[16px] py-[12px] md:px-[20px] md:py-[15px]"
      : "px-5 py-[10px] md:px-[20px] md:py-[14px]";

  return (
    <button
      onClick={onClick}
      className={`text-white inline-flex group capitalize justify-betwee text-center items-center text-[15px] md:text-[16px] border rounded-[6px] transition-all linear duration-300 btn custom-btn mb-0 font-medium leading-[20px] ${padding} border-[#4F46E5] hover:border-[#fff] bg-[#4F46E5] hover:bg-[#090097] text-[#fff]"} ${className}`}
    >
      {name}
      {!noIcon && (
        <span className={`ml-[8px] text-[24px]`}>
          {primaryIcon ? (
            <IoArrowForward
              color={"#fff"}
              className="w-[16px] h-[16px] md:w-[20px] md:h-[20px]"
            />
          ) : (
            <IoHeadsetOutline color={"#fff"} className="text-[20px]" />
          )}
        </span>
      )}
    </button>
  );
};
